import React from 'react';
import {
  Link, Box, Typography,
} from '@mui/material';
import { If } from 'react-if';
import { styled } from '@mui/material/styles';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get as registryGet } from '../lib/appRegistry';

const LogoLink = styled(Link)(() => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
}));

const DocumentLinkMenu = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
}));

const DocumentLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none',
  '&&:hover': {
    color: theme.palette.primary.main,
  },
}));

const SupportText = styled(Typography)(() => ({
  textAlign: 'center',
  fontWeight: '400',
}));

const Root = styled(Box)(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: theme.palette.divider,
  marginTop: '100px',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
}));

function Footer({ sx }) {
  const {
    supportEmail, projectUrl, projectName, logoUrl, developersPortalEnabled,
  } = registryGet('config');
  const location = useLocation();

  return (
    <Root sx={sx}>
      <LogoLink href={projectUrl}>
        <img src={logoUrl} className="logo-footer" alt="logo" />
        <Typography color="text.primary" variant="subtitle2">{projectName}</Typography>
      </LogoLink>
      <If condition={developersPortalEnabled}>
        <DocumentLinkMenu>
          <DocumentLink
            component={RouterLink}
            to="/addon-terms"
            state={{ from: location }}
          >
            Addons terms of use
          </DocumentLink>
          <DocumentLink
            component={RouterLink}
            to="/personal-data-processing-policy"
            state={{ from: location }}
          >
            Personal data processing policy
          </DocumentLink>
        </DocumentLinkMenu>
      </If>
      <SupportText color="text.secondary" variant="subtitle2">
        {`If you have any questions or need help with integration feel free to
        write us at `}
        <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
      </SupportText>
    </Root>

  );
}

Footer.propTypes = {
  sx: PropTypes.object,
};

Footer.defaultProps = {
  sx: {},
};

export default Footer;
